<template>
  <div id="main_home">
    <Siddur size="inner" @toggle_siddur="$emit('toggle_siddur')"  @openTefila="$emit('openTefila', $event)"/>
    <halachot @openTefila="$emit('openTefila', $event)"/>
    <Times/>
    <TefilaForm/>
  </div>
</template>

<script>
import Siddur from '@/components/Siddur.vue';
import TefilaForm from '@/components/TefilaForm.vue';
import halachot from '@/components/halachot.vue';
import Times from '@/components/Times.vue';

export default {
    components: {Siddur, TefilaForm, halachot, Times},
    data(){
        return {
            // tefilot: []
        }
    },
    mounted(){
        // this.api({action: 'tefila/results', data: {  }}, (data) => {

        //     this.tefilot = data.data.items;
        // });
    }

}
</script>

<style lang="scss">
#main_home { padding-top: 40px; height: calc(100vh - 54px); overflow-y: auto; display: flex; flex-direction: column; gap: 30px; background: #F4ECEB; background-size: contain;

}
</style>