<template>
  <div id="thilim_text" v-if="chapters.length > 0" :class="{ font_regular: $store.state.selectedFontStyle === 'regular', font_ashkenaz: $store.state.selectedFontStyle === 'ashkenaz', font_sefard: $store.state.selectedFontStyle === 'sefard',}">
    <div class="chapters" v-for="chapter in chapters" :key="chapter.id">
        <h3 class="title" v-text="chapter.heb_num"></h3>
        <div class="pasuk" v-for="pasuk in chapter.psukim" :key="pasuk.id" v-html="pasuk.html"></div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["type", "num"],
    data(){
        return {
            chapters: []
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init() {
            this.api({ action: 'tehilim/get', data: {type: this.type, num: this.num}}, (data) => {
                this.chapters = data.data.chapters
            }) 
        }
    },
    watch: {
        num() {
            this.init()
        }
    }

}
</script>

<style lang="scss">
#thilim_text {
    .chapters { margin-bottom: 30px;
        .title {font-weight: 600; margin-bottom: 5px;}
        .pasuk {}
    }
}

</style>