<template>
  <div id="podcasts" :class="{main_opened_View: true, opene_from_left: true, opened: is_open}">
    <div class="head">
        <div class="icon_container" @click="$emit('toggle_podcasts')">
          <i class="fas fa-arrow-right"></i>
        </div>
        <h2 class="section_title">שיעורים להאזנה</h2>
    </div>
    <div class="content">

    </div>
  </div>
</template>

<script>
export default {
    props: ['is_open'],
    data() {
        return {

        }
    }

}
</script>

<style>

</style>