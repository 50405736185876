<template>
  <div :class="('main_siddur_' + size)">
    <div class="main_menu" v-if="size === 'big'" :class="{opened: is_open}">
        <div class="head" @click="$emit('toggle_siddur')">
            <i class="fas fa-times"></i>
        </div>
        <div class="content">
            <h2 class="title_section">סידור</h2>
            <ul>
                <li class="item_container" @click="$emit('openTefila', item)" v-for="item in tefilot" :key="item.id">
                    <h4 class="title" v-text="item.title"></h4>
                    <p class="description" v-text="item.description"></p>
                </li>
            </ul>
        </div>
    </div>
    <div class="padding_inline" v-else-if="size === 'inner'">
        <nav>
            <div class="icon_text_container">
                <i class="fas fa-book"></i>
                <p class="text">סידור</p>
                <p class="nusach">אשכנז</p>
            </div>
            <div class="current_tfila">
                <i class="fas fa-sun"></i>
                <span class="tefila">זמן מנחה</span>
            </div>
        </nav>
        <ul>
            <li class="tefila_tag" @click="$emit('openTefila', item)" v-for="item in tefilot" :key="item.id">
                <p class="text" v-text="item.title"></p>
            </li>
            <li class="more" @click="$emit('toggle_siddur')">
                <p class="text"> לתפילות נוספות ></p>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    props: ['size', 'is_open'],
    data(){
        return {
            tefilot: []
        }
    },
    mounted(){
        let limit = this.size == 'inner' ? 8 : false;
        this.api({action: 'tefila/results', data: { limit }}, (data) => {

            this.tefilot = data.data.items;
        });
    }
}
</script>

<style lang="scss">
.main_siddur_big {}

.main_siddur_inner {
    nav {display: flex; align-items: center; justify-content: space-between;  border-bottom: 1px solid rgb(0, 0, 0, 0.4); padding-bottom: 10px; margin-bottom: 15px;
        .icon_text_container {display: flex; align-items: center; gap: 10px;
            i, svg {color: var(--color2); font-size: var(--sectionIcon);}
            .text {color: #000; font-size: var(--sectionTitle);}
            .nusach {font-size: 18px; font-weight: 200; margin-right: 20px;}
        }
        .current_tfila {display: flex; align-items: center; gap: 5px;
            .tefila {font-size: var(--sectionTitle); color: #333;}
            i, svg {color: #333; font-size: 20px;}
        }
    }
    ul { display: flex; flex-wrap: wrap; column-gap: 7px; row-gap: 10px;
        .tefila_tag {padding-inline: 15px; padding-block: 3px; background-color: #fff; color: var(--color2); border-radius: 15px;box-shadow: -5px 5px 15px 0px rgba(0,0,0,0.1);
            .text {font-size: 13px;}
        }
        .more {padding-inline: 15px; padding-block: 3px; background-color: transparent; border: 1px solid var(--color2); color: var(--color2); border-radius: 15px;
            .text {font-size: 13px;}
        }
    }
}
</style>