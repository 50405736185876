<template>
  <div id="font_size">
        <span class="small">א</span>
            <input type="range" v-model="this.$store.state.selectedFontSize" min="14" max="50">
        <span class="big">א</span>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="scss">
#font_size {display: flex; align-items: center; justify-content: stretch; gap: 15px;
    .small { font-size: 14px;}
    .big { font-size: 24px;}
    input[type=range] {flex-basis: 100%;-webkit-appearance: none;-webkit-transition: .2s; transition: opacity .2s; background: rgb(84, 134, 250, 0.3); height: 4px; outline: none;
        &::-webkit-slider-thumb {-webkit-appearance: none; appearance: none; width: 20px; height: 20px; border-radius: 100%; background: var(--color1); cursor: pointer;}
        // &::-webkit-slider-runnable-track { background-color: var(--color1);}
    }
}
</style>