<template>
    <div id="settings" :class="{main_opened_View: true, opene_from_bottom: true, opened: is_open}">
        <div class="head" @click="$emit('toggle_siddur')">
            <div class="icon_container" @click="$emit('toggle_settings')">
                <i class="fas fa-times"></i>
            </div>
            <h2 class="section_title">הגדרות</h2>
            <div class="colors_border">
                <div class="red border"></div>
                <div class="orange border"></div>
                <div class="yellow border"></div>
                <div class="green border"></div>
                <div class="blu_light border"></div>
            </div>
        </div>
        <div class="content">
            <section id="messages" class="padding_inline">
                <h3 class="title">שליחת הודעות</h3>
                <ul>
                    <li @click="activeDailyHalachot">
                        <div class="text_container"><span>2 הלכות ביום</span> <span class="gray">(מידי בוקר)</span></div>
                        <div class="onoffswitch">
                            <input type="checkbox" name="dailyHalachot" id="dailyHalachot" class="onoffswitch-checkbox" v-model="dailyHalachot"  tabindex="0">
                            <label for="dailyHalachot" class="onoffswitch-label"></label>
                        </div>
                    </li>
                    <li @click="activeDailyThilim">
                        <div class="text_container"><span>תהילים יומי</span> <span class="gray">(מידי בוקר)</span></div>
                        <div class="onoffswitch">
                            <input type="checkbox" name="dailyThilim" id="dailyThilim" class="onoffswitch-checkbox" v-model="dailyThilim"  tabindex="0">
                            <label for="dailyThilim" class="onoffswitch-label"></label>
                        </div>
                    </li>
                    <li @click="activeTimeSaturdayEntry">
                        <div class="text_container"><span>זמני כניסת שבת</span> <span class="gray"></span></div>
                        <div class="onoffswitch">
                            <input type="checkbox" name="timeSaturdayEntry" id="timeSaturdayEntry" class="onoffswitch-checkbox" v-model="timeSaturdayEntry"  tabindex="0">
                            <label for="timeSaturdayEntry" class="onoffswitch-label"></label>
                        </div>
                    </li>
                </ul>
            </section>
            <section class="smart_siddur">
                <h3 class="title padding_inline">סידור חכם</h3>
                <FontStyleSettings/>

            </section>
            <section class="font_size padding_inline">
                <h3 class="title">גודל הטקסט</h3>
                <FontSizeSettings/>
            </section>
        </div>
    </div>
</template>

<script>
import FontStyleSettings from '@/components/FontStyleSettings.vue';
import FontSizeSettings from '@/components/FontSizeSettings.vue';
export default {
    props: ['is_open'],
    components: {FontStyleSettings, FontSizeSettings},
    data(){
        return {
            dailyHalachot: false,
            dailyThilim: false,
            timeSaturdayEntry: false,
        }
    },
    medhots: {
        activeDailyHalachot() {

        },
        activeDailyThilim() {

        },
        activeTimeSaturdayEntry() {

        },
    }
}
</script>

<style lang="scss">
#settings {
    .content {padding-top: 20px; display: flex; flex-direction: column; gap: 30px; overflow-y: auto; background: #F4ECEB; flex: 1;
        section {display: flex; flex-direction: column; gap: 15px;
            .title {font-size: 22px; font-weight: 400;}
            .text_container {
                .gray {color: #4e4e4e;}
            }
        }
        #messages {
            .title {}
            ul {    height: auto;
                li { display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid rgb(0, 0, 0, 0.3); padding-block: 15px;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .smart_siddur {}
    }
}




</style>