<template>
  <header id="main_header">
    <div class="hamburger_container" @click="$emit('toggleSidbar')">
        <i class="fas fa-bars"></i>
    </div>
    <div class="logo"></div>
    <div class="date" v-text="hebDate"></div>
    <div class="colors_border">
      <div class="red border"></div>
      <div class="orange border"></div>
      <div class="yellow border"></div>
      <div class="green border"></div>
      <div class="blu_light border"></div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["hebDate"]

}
</script>

<style lang="scss">
#main_header {position: relative; display: flex; align-items: center; justify-content: space-between; padding-inline: 10px; background-color: #fff;
    .hamburger_container {color: var(--color2); font-size: 18px;}
    .logo {background-image: url(../assets/otw_logo.jpg); background-position: center; background-size: contain; background-repeat: no-repeat; width: 200px; height: 50px;}
    .date {color: var(--color2); font-size: 14px;}
    .colors_border {top: 100%;
        .border {opacity: 1;}
    }
}
</style>