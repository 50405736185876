<template>
  <div id="main_side_bar" :class="{opened: is_open}">
    <div class="close_hamburger" @click="$emit('toggleSidbar')">
        <i class="fas fa-times"></i>
    </div>
    <ul>
        <li @click="$emit('toggle_siddur')">
            <div class="icon_container"><i class="fas fa-book"></i></div>
            <p class="text">סידור חכם</p>
        </li>
        <li @click="$emit('toggleThilimMenu')">
            <div class="icon_container"><i class="fas fa-microchip"></i></div>
            <p class="text">תהילים</p>
        </li>
        <li @click="$emit('toggle_podcasts')">
            <div class="icon_container"><i class="fas fa-headphones"></i></div>
            <p class="text">פודקאסטים</p>
        </li>
        <li @click="$emit('toggleAskTheRabbi')">
            <div class="icon_container"><i class="fas fa-comments"></i></div>
            <p class="text">שאל את הרב</p>
        </li>
        <!-- <li>
            <div class="icon_container"><i class="fas fa-seedling"></i></div>
            <p class="text">סגולות</p>
        </li> -->
        <li @click="$emit('toggle_settings')">
            <div class="icon_container"><i class="fas fa-cog"></i></div>
            <p class="text">הגדרות</p>
        </li>
        <!-- <li>
            <div class="icon_container"><i class="fas fa-exclamation-triangle"></i></div>
            <p class="text">דיווח על שגיאה</p>
        </li> -->
    </ul>
  </div>
</template>

<script>
export default {
    props: ['is_open']

}
</script>

<style lang="scss">
#main_side_bar {transition:transform 0.3s ease 0s; position: fixed; top: 0; bottom: 0; right: 0; transform: translateX(+100%); width: 80vw; background-color: #fff; z-index: 9999; 
    &::after { content: ""; position: absolute; inset: 0; right: -100%; width: 100vw; z-index: 9998; background: rgba(0, 0, 0, 0.0); transition:background 0.3s ease 0s; }
    &.opened {transform: translateX(0%);
        &::after { background: rgba(0, 0, 0, 0.5);  right: 100%;}
        .close_hamburger {display: block;}
    }
    .close_hamburger {position: absolute; top: 0px; right: calc(100% + 20px); color: var(--color2); font-size: 30px; z-index: 9999; display: none;}
    ul {width: 100%;
        li {display: flex; align-items: center; gap: 25px; border-bottom: 1px rgba(0, 0, 0, 0.2) solid; height: 75px;
            &::before {
                content: '';
                display: inline-block;
                height: 100%; 
                width: 5px;
                background-color: red;
            }
            &:nth-child(1) {&::before{ background-color: rgb(57, 135, 236);}}
            &:nth-child(2) {&::before{ background-color: rgb(207, 30, 30);}}
            &:nth-child(3) {&::before{ background-color: rgb(255, 143, 16);}}
            &:nth-child(4) {&::before{ background-color: rgb(255, 251, 0);}}
            &:nth-child(5) {&::before{ background-color: rgb(0, 153, 13);}}
            &:nth-child(6) {&::before{ background-color: rgb(132, 0, 255);}}
            &:nth-child(7) {&::before{ background-color: rgb(255, 124, 16);}}
            .icon_container {color: #000;font-size: 22px}
            .text {color: #000; font-size: 18px;}
        }
    }
}
</style>