<template>
  <div class="app">
    <Header @toggleSidbar="sidebar_open = !sidebar_open" :hebDate="hebDate" />
    <SideMenu :is_open="sidebar_open" @toggleThilimMenu="toggale('thilimMenu')" @toggleSidbar="toggale('sideBar')" @toggle_siddur="toggale('siddur')" @toggle_settings="toggale('settings')" @toggle_podcasts="toggale('podcasts')"  @toggleAskTheRabbi="toggale('ask_the_rabbi')" />
    <Siddur :is_open="siddur_open" size="big" @toggle_siddur="toggale('siddur')" @openTefila="openTefila"/>
    <tefila :tefila="selected_tefila" @toggaleTefila="selected_tefila = false" @toggaleSettingsSmall="toggale('settingsSmall')"/>
    <Settings :is_open="settings_open"  @toggle_settings="toggale('settings')"/>
    <Podcasts :is_open="podcasts_open"  @toggle_podcasts="toggale('podcasts')"/>
    <SettingsSmall :is_open="settingsSmallOpen" @toggaleSettingsSmall="toggale('settingsSmall')"/>
    <ThilimMenu :is_open="thilimMenuOpen" :hebDate="hebDate" @toggleThilimMenu="toggale('thilimMenu')" @toggleThilim="thilimType = $event; toggale('thilim')"/>
    <Thilim :is_open="thilimOpen" :type="thilimType" @toggaleThilim="toggale('thilim')" @toggaleSettingsSmall="toggale('settingsSmall')"/>
    <AskedTheRabbi :is_open="ask_the_rabbi_open"  @toggleAskTheRabbi="toggale('ask_the_rabbi')" />
    <router-view @toggle_siddur="toggale('siddur')" @openTefila="openTefila"/>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import SideMenu from './components/SideMenu.vue';
import Siddur from './components/Siddur.vue';
import tefila from './views/tefila.vue';
import Settings from './views/Settings.vue';
import ThilimMenu from './views/ThilimMenu.vue';
import SettingsSmall from './components/SettingsSmall.vue';
import Thilim from './views/Thilim.vue';
import Podcasts from './views/Podcasts.vue';
import AskedTheRabbi from './views/AskTheRabbi.vue';

export default {  
  name: 'app',
  components: {Header, SideMenu, Siddur, tefila, Settings, ThilimMenu, SettingsSmall, Thilim, Podcasts, AskedTheRabbi},
  data() {
    return {
      loaded: false,
      hebDate: '',
      thilimType: '',
      selected_tefila: false,
      sidebar_open: false,
      siddur_open: false,
      settings_open: false,
      thilimMenuOpen: false,
      settingsSmallOpen: false,
      thilimOpen: false,
      podcasts_open: false,
      ask_the_rabbi_open: false,
    }
  },
  
  mounted(){
    this.reload();
    
  },
  methods: {
    toggale(name) {
      if (name === 'sideBar') { this.closeAll(name); this.sidebar_open = !this.sidebar_open; }
      else if (name === 'siddur') { this.closeAll(name); this.siddur_open = !this.siddur_open;}
      else if (name === 'settings') { this.closeAll(name); this.settings_open = !this.settings_open;  }
      else if (name === 'thilimMenu') { this.closeAll(name); this.thilimMenuOpen = !this.thilimMenuOpen;}
      else if (name === 'thilim') { this.closeAll(name); this.thilimOpen = !this.thilimOpen; }
      else if (name === 'podcasts') { this.closeAll(name); this.podcasts_open = !this.podcasts_open }
      else if (name === 'ask_the_rabbi') { this.closeAll(name); this.ask_the_rabbi_open = !this.ask_the_rabbi_open }
      else if (name === 'settingsSmall') {  this.settingsSmallOpen = !this.settingsSmallOpen; }
    },
    closeAll(name) {
      if (name !== 'sideBar') {this.sidebar_open = false}
      if (name !== 'siddur') {this.siddur_open = false}
      if (name !== 'settings') {this.settings_open = false}
      if (name !== 'thilimMenu') {this.thilimMenuOpen = false}
      if (name !== 'thilim') {this.thilimOpen = false}
      if (name !== 'podcasts') {this.podcasts_open = false}
    },

    openTefila(tefila) {
      this.siddur_open = false
      this.sidebar_open = false
      this.selected_tefila = tefila
    },

    
    
    reload(){
      // categories
      this.api({ action: 'general'}, (data) => {
        // this.menu = data.data.menu;
        this.$store.words = data.data.words;
        this.hebDate = data.data.heb_date;
        this.loaded = true;
      });
    },
  }
}
</script>
