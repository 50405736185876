<template>
  <div id="tefila" :class="{tefila_page: true, opened: tefila != false && tefila}">
    <div class="steps_btn" @click="parts_model_active = true" v-if="tefila?.parts && tefila.parts.length > 1"><i class="fas fa-bookmark"></i></div>
    <div :class="{parts_model: true, active: parts_model_active}" v-if="tefila?.parts && tefila.parts.length > 1">
        <div class="bg_model" @click="parts_model_active = false"></div>
        <ul>
            <li v-for="part in partsComp" :key="part.id">
                <a @click="parts_model_active = false" :href="'#' + part.id" v-text="part.displayTitle"></a>
            </li>
        </ul>
    </div>
    <div class="head">
        <div class="back_icon" @click="$emit('toggaleTefila')"><i class="fas fa-arrow-right"></i></div>
        <h2 class="title" v-text="tefila.title"></h2>
        <div class="settings" @click="$emit('toggaleSettingsSmall')"><i class="fas fa-cog"></i></div>
    </div>
    <div :class="{content: true, font_regular: $store.state.selectedFontStyle === 'regular', font_ashkenaz: $store.state.selectedFontStyle === 'ashkenaz', font_sefard: $store.state.selectedFontStyle === 'sefard',}">
        <ul class="parts_container" :style="{fontSize: this.$store.state.selectedFontSize + 'px'}">
            <template v-if="tefila?.parts">
                <li :id="part.id" class="part_container" :class="{ expansion: part.expansion == 'true'}" v-for="part in tefila.parts" :key="part.id">
                    <div :class="{expansion_box: true, opened: part?.opened}" v-if="part.expansion == 'true'">
                        <div class="head" @click="part.opened = !part.opened">
                            <h5 class="title" v-text="part.displayTitle"></h5>
                            <div class="icon_container" v-if="!part.opened"><i class="fas fa-plus"></i></div>
                            <div class="icon_container" v-else><i class="fas fa-minus"></i></div>
                        </div>
                        <div class="content">
                            <div v-html="part.body"></div>
                        </div>
                    </div>
                    <div v-else v-html="part.body"></div>
                </li>
            </template>
            <template v-else-if="tefila?.body">
                <li class="part_container">
                    <div v-html="tefila.body"></div>
                </li>
            </template>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    props: ['tefila'],
    data() {
        return {
            parts_model_active: false, 
            openedExpansions: {}
        }
    },
    methods: {

    },
    computed:{
        partsComp(){
            return this.tefila.parts.filter(row => row.displayTitle != '');
        }
    }
}
</script>

<style lang="scss">
#tefila {
    .parts_container { display: flex; flex-direction: column; gap: 20px; padding-top: 20px;
        .part_container {
            .expansion_box {background: #fff; overflow: hidden; max-height: 58px; border-radius: 7px;
                &.opened {max-height: 5000px; /*overflow-y: auto;*/ transition: all 1.0s ease; 
                    .head {}
                    .content { transform: translateY(0%);}
                }
                .head { background-color: #fff; display: flex; justify-content: space-between; padding: 20px;
                    .title{ color: #000; font-weight: 600; font-size: 18px;}
                    i, svg {}
                }
                .content { display: flex; flex-direction: column; background-color: #fff; padding: 20px;}
            }
        }
    }
}

</style>