<template>
  <div id="halachot">
    <nav class="padding_inline">
        <div class="icon_text_container">
            <i class="fab fa-draft2digital"></i>
            <p class="text">הלכות ביום</p>
        </div>
        <p class="status">מתעדכן בכל יום</p>
    </nav>
    <div :class="{content: true, font_regular: $store.state.selectedFontStyle === 'regular', font_ashkenaz: $store.state.selectedFontStyle === 'ashkenaz', font_sefard: $store.state.selectedFontStyle === 'sefard',}">
        <ul class="padding_inline">
            <li class="halacha_container" v-for="item in halachot" :key="item.id">
                <div class="colors_border">
                    <div class="red border"></div>
                    <div class="orange border"></div>
                    <div class="yellow border"></div>
                    <div class="green border"></div>
                    <div class="blue border"></div>
                    <div class="purple border"></div>
                    <div class="blu_light border"></div>
                </div>
                <div class="text">
                    <h4 class="title" v-html="item.title"></h4>
                    <div class="body_container font_ashkenaz" v-html="item.body"></div>
                </div>
                <div class="more" @click="$emit('openTefila', item)">
                    <span>קרא עוד</span>
                    <i class="fas fa-arrow-left"></i>
                </div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            halachot: []
        }
    },
    mounted(){
        this.api({action: 'halachot/results', data: { limit: 8 }}, (data) => {
            this.halachot = data.data.items;
        });
    }

}
</script>

<style lang="scss">
#halachot {
    nav {display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px;
        .icon_text_container {display: flex; align-items: center; gap: 10px;
            i, svg {color: var(--color2); font-size: var(--sectionIcon);}
            .text {color: #000; font-size: 18px;}
        }
        .status {color: #333; font-size: 14px;}
    }
    .content {
        ul { overflow-x: auto; display: flex; gap: 15px; &::-webkit-scrollbar {display: none;}
            .halacha_container {position: relative; overflow: hidden; background: #fff; display: flex; flex-direction: column; justify-content: space-between; gap: 20px; border-radius: 10px; flex-shrink: 0; padding-inline: 10px; padding-block: 15px; width: 70vw; 
                
                .text {display: flex; flex-direction: column; gap: 5px;
                    .title {color: #000;overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical;font-weight: 600; font-size: 20px; line-height: 1.5;}
                    .body_container {color: #000;overflow: hidden; display: -webkit-box; -webkit-line-clamp: 7; line-clamp: 7; -webkit-box-orient: vertical;line-height: 1.3; font-size: 18px;}
                }
                .more { color: var(--color2); text-align: left; font-size: 12px;
                    span { margin-left: 5px;}
                    i, svg {}
                }

            }
        }
    }
}
</style>