<template>
  <div id="settings_small" :class="{settings_model: true, active: is_open}" >
        <div class="bg_model" @click="$emit('toggaleSettingsSmall')"></div>
        <div class="settings_container">
            <!-- <div class="mute" :class="{silent_mode: silentMode}" @click="toggleSilentMode">
                <div class="text">השתק בזמן תפילה</div>
                <div v-show="silentMode"><i class="fas fa-volume-mute"></i></div>
                <div v-show="!silentMode"><i class="fas fa-volume-up"></i></div>
            </div> -->
            <div class="font_size">
                <FontSizeSettings/>
            </div>
            <div class="font_style">
                <FontStyleSettings/>
            </div>
        </div>
    </div>
</template>

<script>
import FontStyleSettings from './FontStyleSettings.vue';
import FontSizeSettings from './FontSizeSettings.vue';

export default {
    props: ['is_open'],
    components: {FontStyleSettings,FontSizeSettings},
    data() {
        return {
            silentMode: false,
        }
    },
    methods: {
        toggleSilentMode() {
            this.silentMode = !this.silentMode 
        }
    }
}
</script>

<style lang="scss">
.settings_model { z-index: 99999;
    &.active {
        .bg_model {opacity: 0.5; pointer-events: all;}
        .settings_container {transform: translateY(0%);}
    }
    .bg_model { z-index: 99999;position: fixed; inset: 0; background: rgba(0, 0, 0); opacity: 0; pointer-events: none;transition: all 0.1s ease 0s;}
    .settings_container { z-index: 99999;transition: all 0.1s ease 0s; position: fixed; inset: 0; top: unset; transform: translateY(100%); background: #FAFAFA; padding-bottom: 15px; display: flex; flex-direction: column;
        .mute { display: flex; justify-content: space-between; border-bottom: 1px solid rgba(0,0,0,0.1); padding-inline: var(--pd-home-pg); padding-block: 15px;
            .text {color: #000; font-size: 16px;}
            &.silent_mode {
                i, svg {color: #808080; font-size: 18px;}
            }
            i, svg {color: var(--color1); font-size: 18px;}
            
            
        }
        .font_size {padding-inline: var(--pd-home-pg); padding-block: 15px;}
        .font_style { }
    }
}

</style>