<template>
  <div id="main_font_style">
    <ul class="padding_inline">
        <li @click="this.$store.state.selectedFontStyle = 'ashkenaz'" :class="{active: this.$store.state.selectedFontStyle === 'ashkenaz'}">
            <div class="icon_container">
                <i class="fas fa-check"></i>
            </div>
            <span class="text">פונט אשכנזי</span>
        </li>
        <li  @click="this.$store.state.selectedFontStyle = 'sefard'" :class="{active: this.$store.state.selectedFontStyle === 'sefard'}">
            <div class="icon_container">
                <i class="fas fa-check"></i>
            </div>
            <span class="text">פונט ספרדי</span>
        </li>
        <li  @click="this.$store.state.selectedFontStyle = 'regular'" :class="{active: this.$store.state.selectedFontStyle === 'regular'}">
            <div class="icon_container">
                <i class="fas fa-check"></i>
            </div>
            <span class="text">פונט רגיל</span>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style lang="scss">
#main_font_style{
    ul { display: flex; gap: 5px; overflow-x: auto;&::-webkit-scrollbar {display: none;}
        li { width: 160px; height: 85px; display: flex; align-items: center; justify-content: center; gap: 7px; border-radius: 6px; flex-shrink: 0; background: #fff;
            &.active { background: var(--color1);
                .icon_container {display: flex;}
                .text {color: #fff;}
            }
            .icon_container { border-radius: 100%; background: #fff; justify-content: center; align-items: center; width: 20px; height: 20px; display: none;
                i, svg {color: var(--color1);font-size: 14px;}
            }
            .text {color: #ABABAB; white-space: nowrap; }
        }
    }
}
</style>