<template>
  <div id="times" class="padding_inline">
    <nav>
        <div class="icon_text_container">
            <i class="fas fa-stopwatch"></i>
            <p class="text">זמני היום</p>
        </div>
        <div class="current_city">
            <div class="custome-select">
                <select class="styled-select" v-model="selectedCity">
                    <option id="1" value="Jerusalem" >ירושלים</option>
                    <option id="2" value="haifa" >חיפה</option>
                </select>
            </div>
        </div>
    </nav>
    <div class="content" v-if="times != false">
        <!-- <ul v-for="(time, key) in times" :key="key">
            <template v-if="key !== 'shabat'">
                <li v-for="(item, key) in time" :key="key" >
                    <span class="text" v-text="item.title"></span><span class="time"  v-text="item.time"></span>
                </li>
            </template>
        </ul> -->
        <ul>
            <li>
                <span class="text">עלות השחר</span><span class="time"  v-text="times.morning.Aube.time"></span>
            </li>
            <li>
                <span class="text">זמן תפילין וטלית</span><span class="time" v-text="times.morning.DebutTephilin.time"></span>
            </li>
            <li>
                <span class="text">הנץ החמה</span><span class="time" v-text="times.morning.LeverSoleil.time"></span>
            </li>
            <li>
                <span class="text">סזק"ש מג"א</span><span class="time" v-text="times.morning.ZemaneChema1.time"></span>
            </li>
            <li>
                <span class="text">סזק"ש גר"א</span><span class="time" v-text="times.morning.ZemaneChema2.time"></span>
            </li>
            <li>
                <span class="text">תפילה מג"א</span><span class="time" v-text="times.morning.ZemaneTephila1.time"></span>
            </li>
            <li>
                <span class="text">תפילה גר"א</span><span class="time" v-text="times.morning.ZemaneTephila2.time"></span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="text">חצות</span><span class="time" v-text="times.midday.Hatsot.time"></span>
            </li>
            <li>
                <span class="text">מנחה גדולה</span><span class="time" v-text="times.midday.MinhaGuedola.time"></span>
            </li>
            <li>
                <span class="text">מנחה קטנה</span><span class="time" v-text="times.midday.MinhaKetana.time"></span>
            </li>
            <li>
                <span class="text">פלג הגר"א</span><span class="time" v-text="times.midday.Plag.time"></span>
            </li>
            <li>
                <span class="text">פלג מג"א</span><span class="time" v-text="times.midday.Plag2.time"></span>
            </li>
            <li>
                <span class="text">שקיעה</span><span class="time" v-text="times.midday.CoucherSoleil.time"></span>
            </li>
            <li>
                <span class="text">צאת הכוכבים</span><span class="time" v-text="times.midday.etoiles708.time"></span>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            selectedCity: 'Jerusalem',
            times: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.api({ action: 'times/get_in_hebrew', data: { ville: this.selectedCity }}, (data) => {
                this.times = data.data
            })
        }
    },
    watch: {
        selectedCity() {
            this.init()
        }
    }
}
</script>

<style lang="scss">
#times {
    nav {display: flex; align-items: center; justify-content: space-between;  border-bottom: 1px solid rgb(0, 0, 0, 0.4); padding-bottom: 10px; margin-bottom: 15px;
        .icon_text_container {display: flex; align-items: center; gap: 10px;
            i, svg {color: var(--color2); font-size: var(--sectionIcon);}
            .text {color: #000; font-size: var(--sectionTitle);}
            .nusach {font-size: 18px; font-weight: 200; margin-right: 20px;}
        }
        .current_city {display: flex; align-items: center; gap: 5px;
            .tefila {font-size: var(--sectionTitle); color: #333;}
            i, svg {color: #333; font-size: 20px;}
        }
    }
    .content {display: flex; justify-content: space-around; gap: var(--pd-home-pg);
        ul {display: flex; flex-direction: column; gap: var(--pd-home-pg);
            li {display: flex; align-items: center; gap: 5px; justify-content: space-between;
                .text {}
                .time {font-weight: 600; font-size: 17px;}
            }
        }
    }
}
</style>