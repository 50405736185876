<template>
    <div class="main_menu" :class="{opened: is_open}">
        <div class="head" @click="$emit('toggleThilimMenu')">
            <i class="fas fa-times"></i>
        </div>
        <div class="content">
            <h2 class="title_section">תהילים</h2>
            <ul>
                <li class="item_container" @click="$emit('toggleThilim', 'all')">
                    <h4 class="title">ספר תהילים המלא</h4>
                    <p class="description">תהילים א-קנ</p>
                </li>
                <li class="item_container" @click="$emit('toggleThilim', 'diw')">
                    <h4 class="title">תהילים לפי יום בשבוע</h4>
                    <p class="description">ליום רביעי</p>
                </li>
                <li class="item_container" @click="$emit('toggleThilim', 'dim')">
                    <h4 class="title">תהילים לפי יום בחודש</h4>
                    <p class="description" v-text="hebDate"></p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['hebDate', 'is_open']
}
</script>

<style>

</style>