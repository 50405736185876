<template>
  <div id="ask_the_rabbi" :class="{main_opened_View: true, opene_from_left: true, opened: is_open}">
    <div class="head">
        <div class="icon_container" @click="$emit('toggleAskTheRabbi')">
          <i class="fas fa-arrow-right"></i>
        </div>
        <h2 class="section_title">שאל את הרב</h2>
    </div>
    <div class="content padding_inline">
      <ul class="cotegories_container">
        <li :class="{category_tag: true, selected: selectedCategory === category.id}" @click="selectedCategory = category.id" v-for="(category, i) in cotegories" :key="category.id" :style="'--color:' + '#' + colors[i]">
          <p class="text" v-text="category.name"></p>
        </li>
      </ul>
      <ul class="faqs_container">
        <li :class="{main_faq: true, opened: faq.opened}" v-for="faq in faqs" :key="faq.id">
          <div class="head" @click="faq.opened = !faq.opened">
            <h5 class="title" v-text="faq.title"></h5>
            <div class="icon_container" v-if="!faq.opened"><i class="fas fa-plus"></i></div>
            <div class="icon_container" v-else><i class="fas fa-minus"></i></div>
          </div>
          <div class="faq_container">
            <div class="question" v-html="faq.question"></div>
            <p class="answer_title">תשובת הרב</p>
            <div class="answer" v-html="faq.answer"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['is_open'],
  data() {
    return {
      cotegories: [],
      faqs: [],
      selectedCategory: null,
      colors: ['6EA3CB','E7B660','94519C','96C199','E8A3BB','9B53A1','ADA9A6','DC8B70','8085B6','DEE3E6','89736A','663BB8','B7D08F','CFD477','B4EACC','6EA3CB','E7B660','94519C','96C199','E8A3BB','9B53A1','ADA9A6','DC8B70','8085B6','DEE3E6','89736A','663BB8','B7D08F','CFD477','B4EACC','6EA3CB','E7B660','94519C','96C199','E8A3BB','9B53A1','ADA9A6','DC8B70','8085B6','DEE3E6','89736A','663BB8','B7D08F','CFD477','B4EACC','6EA3CB','E7B660','94519C','96C199','E8A3BB','9B53A1','ADA9A6','DC8B70','8085B6','DEE3E6','89736A','663BB8','B7D08F','CFD477','B4EACC','6EA3CB','E7B660','94519C','96C199','E8A3BB','9B53A1','ADA9A6','DC8B70','8085B6','DEE3E6','89736A','663BB8','B7D08F','CFD477','B4EACC']
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.api_hdb({ action: 'faq/get_categories', data: {  }}, (data) => {
          this.cotegories = data.data
          this.selectedCategory = data.data[0].id
          this.getCategoryFaq()
      })
    },
    getCategoryFaq() {
      this.api_hdb({ action: 'faq/results', data: { categories: [this.selectedCategory] }}, (data) => {
          this.faqs = data.data.items
          for (let index = 0; index < this.faqs.length; index++) {
            this.faqs[index]['opened'] = false
          }
      })
    }
  },
  watch: {
    selectedCategory() {
      this.getCategoryFaq()
    }
  }
}
</script>

<style lang="scss">
#ask_the_rabbi {
  .content {padding-top: 20px; display: flex; flex-direction: column; gap: 20px; overflow-y: auto; min-height: unset; padding-bottom: 20px;
    .cotegories_container { flex-shrink: 0;  display: flex; flex-wrap: wrap; column-gap: 7px; row-gap: 10px;
      .category_tag {padding-inline: 15px; padding-block: 3px; background: transparent; color: #000; border-radius: 15px; border: 1px var(--color) solid;
        &.selected {
          color: #fff;
          background: var(--color);
        }
        .text {font-size: 13px;}
      }
    }
    .faqs_container { flex-shrink: 0; border-radius: 10px; overflow: hidden; background-color: #fff;
      .main_faq {padding-inline: 15px; overflow: hidden;transition: all 0.3s ease 0s;max-height: 52px;
        &.opened {max-height: unset;
          .head {}
          .faq_container { transform: translateY(0%);}
        }
        .head { background-color: #fff; display: flex; justify-content: space-between; padding-block: 15px; position: relative; z-index: 2;
          .title{ color: #000; font-weight: 600; font-size: 18px;}
          i, svg {}
        }
        .faq_container { display: flex; flex-direction: column; background-color: #fff; transform: translateY(-100%); transition: all 0.3s ease 0s;}
      }
    }
  }
}
</style>