<template>
  <div v-if="type" id="thilim" class="tefila_page" :class="{opened: is_open}" >
    <div class="steps_btn" @click="parts_model_active = true" v-if="true"><i class="fas fa-bookmark"></i></div>
    <div :class="{parts_model: true, active: parts_model_active}" v-if="true">
        <div class="bg_model" @click="parts_model_active = false"></div>
        <ul>
            <!-- <li v-for="part in tefila.parts" :key="part.id">
                <a @click="parts_model_active = false" :href="'#' + part.id" v-text="part.title"></a>
            </li> -->
            <li><a href="ksjdhfsdkjfh"></a></li>
            <li><a href="ksjdhfsdkjfh"></a></li>
            <li><a href="ksjdhfsdkjfh"></a></li>
        </ul>
    </div>
    
    <div class="head">
        <div class="back_icon" @click="$emit('toggaleThilim')"><i class="fas fa-arrow-right"></i></div>
        <div class="custome-select" v-if="type === 'diw'">
            <select class="styled-select" v-model="num">
                <option id="1" value="1" >ליום ראשון</option>
                <option id="2" value="2" >ליום שני</option>
                <option id="3" value="3" >ליום שלישי</option>
                <option id="4" value="4" >ליום רביעי</option>
                <option id="5" value="5" >ליום חמישי</option>
                <option id="6" value="6" >ליום שישי</option>
                <option id="7" value="7" >ליום שבת</option>
            </select>
        </div>
        <div class="custome-select" v-else-if="type === 'dim'">
            <select class="styled-select" v-model="num">
                <option id="1" value="א" >ליום א בחודש</option>
                <option id="1" value="ב" >ליום ב בחודש</option>
                <option id="1" value="ג" >ליום ג בחודש</option>
                <option id="1" value="ד" >ליום ד בחודש</option>
                <option id="1" value="ה" >ליום ה בחודש</option>
                <option id="1" value="ו" >ליום ו בחודש</option>
                <option id="1" value="ז" >ליום ז בחודש</option>
                <option id="1" value="ח" >ליום ח בחודש</option>
                <option id="1" value="ט" >ליום ט בחודש</option>
                <option id="1" value="י" >ליום י בחודש</option>
                <option id="1" value="יא" >ליום יא בחודש</option>
                <option id="1" value="יב" >ליום יב בחודש</option>
                <option id="1" value="יג" >ליום יג בחודש</option>
                <option id="1" value="יד" >ליום יד בחודש</option>
                <option id="1" value="טו" >ליום טו בחודש</option>
                <option id="1" value="טז" >ליום טז בחודש</option>
                <option id="1" value="יז" >ליום יז בחודש</option>
                <option id="1" value="יח" >ליום יח בחודש</option>
                <option id="1" value="יט" >ליום יט בחודש</option>
                <option id="1" value="כ" >ליום כ בחודש</option>
                <option id="1" value="כא" >ליום כא בחודש</option>
                <option id="1" value="כב" >ליום כב בחודש</option>
                <option id="1" value="כג" >ליום כג בחודש</option>
                <option id="1" value="כד" >ליום כד בחודש</option>
                <option id="1" value="כה" >ליום כה בחודש</option>
                <option id="1" value="כו" >ליום כו בחודש</option>
                <option id="1" value="כז" >ליום כז בחודש</option>
                <option id="1" value="כח" >ליום כח בחודש</option>
                <option id="1" value="כט" >ליום כט בחודש</option>
                <option id="1" value="ל" >ליום ל בחודש</option>
            </select>
        </div>
        <div class="settings" @click="$emit('toggaleSettingsSmall')"><i class="fas fa-cog"></i></div>
    </div>
    <div class="content" v-if="type != '' && is_open">
        <tehilim-text :type="type" :num="num"/>
    </div>

  </div>
</template>

<script>
import tehilimText from '@/components/tehilim/tehilimText.vue'
export default {
    props: ['is_open', 'type'],
    components: { tehilimText },
    data() {
        return {
            parts_model_active: false,
            settings_model_active: false,
        }
    },
    mounted() {
        
    },
    computed: {
        num(){
            return this.type === 'diw' ? '1' : 'א';
        }
    }
}
</script>

<style lang="scss">

</style>