<template>
  <div id="tefila_form">
    <div class="head padding_inline">
        <i class="fas fa-book-open"></i>
        <h2 class="title">שלח שם לתפילה</h2>
    </div>
    <div class="content">
        <form @submit.prevent="add_name_for_tefila">
            <h4 class="form_title">תלמידי ישיבת הרב זמיר כהן בביתר עלית יקדישו את לימוד התורה שלהם עבורך. נא למלא את הפרטים, השירות ללא עלות</h4>
            <ul>
                <li>
                    <input v-model="tefilaForm.name" type="text" name="" id="">
                    <label :class="{active: tefilaForm.name != ''}" for="">שם</label>
                </li>
                <li>
                    <input v-model="tefilaForm.mother_name" type="text" name="" id="">
                    <label :class="{active: tefilaForm.mother_name != ''}" for="">שם האם</label>
                </li>
                <li>
                    <input v-model="tefilaForm.email" type="email" name="" id="">
                    <label :class="{active: tefilaForm.email != ''}" for="">כתובת דוא"ל</label>
                </li>
                <li>
                    <input v-model="tefilaForm.phone" type="tel" name="" id="">
                    <label :class="{active: tefilaForm.phone != ''}" for="">מספר טלפון</label>
                </li>
                <li>
                    <input v-model="tefilaForm.personal_request" type="text" name="" id="">
                    <label :class="{active: tefilaForm.personal_request != ''}" for="">בקשה אישית</label>
                </li>
                <li>
                    <input type="submit" value="שליחה">
                </li>
            </ul>
        </form>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            tefilaForm: {
                name: '',
                mother_name: '',
                email: '',
                phone: '',
                personal_request: '',
            }
        }
    },
    methods: {
        add_name_for_tefila() {

            this.api({action: 'name_for_tefila/add', data: this.tefilaForm, method: 'post'}, (data) => {
                if (data.data) {
                    this.tefilaForm = {
                        name: '',
                        mother_name: '',
                        email: '',
                        phone: '',
                        personal_request: '',
                    }
                    alert('ההודעה נשלחה בהצלחה')
                } else {
                    alert('כשל השליחת ההודעה נסה מאוחר יותר')
                }
            });
        }
    }
}
</script>

<style lang="scss">
#tefila_form {
    .head { display: flex; gap: 10px; padding-block: 10px;
        i, svg {color: var(--color2); font-size: var(--sectionIcon);}
        .title {font-size: var(--sectionTitle); color: #000; font-weight: 400; }
    }
    .content { background-image: url(../assets/book_bg.jpg); background-size: contain; padding: 15px;
        form { background: var(--color2); border-radius: 10px; padding: 15px;
            .form_title {font-weight: 600; color: #fff; font-size: 18px; margin-bottom: 20px; line-height: 1.2;}
            ul { display: flex; flex-direction: column; gap: 20px;
                li { display: flex; flex-direction: column; position: relative; padding-top: 10px;
                    label { position: absolute; top: 0px; right: 0px; pointer-events: none; transition: transform 0.1s ease 0s; color: #fff; font-size: 14px;}
                    input:focus + label, label.active { transform: translateY(-8px) scale(0.85); }
                    input {transition: border-bottom 0.1s ease 0s; width: 100%; font-weight: 400; font-size: 14px; background: initial; border: none; border-radius: 0; color: #000; border-bottom: 1px solid rgba(0,0,0,0.7); outline: none;
                        &:focus {border-bottom: 2px #fff solid;}
                    }
                    input[type=submit] {width: 80%; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: var(--radius); font-weight: 600; font-size: 16px; color: var(--color2); background: #fff; border: none; margin: 0 auto;} 
                }
            }
        }
    }
}

</style>